#words-list > span {
    color: #e03997;
    /*height: 1.2em;*/
    overflow: hidden;
    vertical-align: bottom;
    display: inline-block;
    position: relative;
    text-align: center;
    /* Can have an effect on bugs when adding words to animation, so fiddle with it lol */
    animation-duration: 12s;
    animation-timing-function: steps(25, end);
    animation-iteration-count: infinite;

}


#placeholder-caret::before {
    content: "|";
    position: absolute;
    animation: caret infinite;
    animation-duration: 1s;
    animation-timing-function: steps(1, end);
}


#text_1 {
    animation: text1;
}
#text_2 {
    animation: text2;
}
#text_3 {
    animation: text3;
}
#text_4 {
    animation: text4;
}
#text_6 {
    animation: text6;
}

#text_5 {
    animation: text5;
}


#text_1_mobile {
    animation: text1_mobile;
}
#text_2_mobile {
    animation: text2_mobile;
}
#text_3_mobile {
    animation: text3_mobile;
}
#text_4_mobile {
    animation: text4_mobile;
}
#text_6_mobile {
    animation: text6_mobile;
}

#text_5_mobile {
    animation: text5_mobile;
}


@keyframes text1 {
    0%, 13%, 100% {
        width: 0;

    }
    3%, 10% {
        width: 5.79rem; /* 2.5x font of text*/
    }

}

/* 15-17 is duration of writing */
/* 17-25 is duration of staying after written */
/* 25-28 is duration of erasing */
@keyframes text2 {
    0%, 13%, 26%, 100% {
        width: 0;
    }

    16%, 23% {
        width: 2.83rem; /* 1.228x font of text*/
    }
}

@keyframes text3 {
    0%, 26%, 39%, 100% {
        width: 0;
    }

    29%, 36% {
        width: 3.91rem; /* 1.695x font of text */
    }
}

@keyframes text4 {
    0%, 39%, 52%, 100% {
        width: 0;
    }

    42%, 49% {
        width: 6.75rem; /* 2.923x font of text */
    }
}

@keyframes text5 {
    0%, 52%, 70%, 100% {
        width: 0;
    }
    58%, 65% {
        width: 11.07rem; /* 4.795x font of text */
    }
}

@keyframes text6 {
    0%, 70%, 97%, 100% {
        width: 0;
    }

    73%, 78% {
        width: 5.94rem; /* 2.573x font of text */
        color: #fcf113;
    }
    83%, 97% {
        width: 5.94rem;
        color: #fcf113;
    }
}

@keyframes caret {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}


@keyframes text1_mobile {
    0%, 13%, 100% {
        width: 0;

    }
    3%, 10% {
        width: 4.3rem;
    }

}

/* 15-17 is duration of writing */
/* 17-25 is duration of staying after written */
/* 25-28 is duration of erasing */
@keyframes text2_mobile {
    0%, 13%, 26%, 100% {
        width: 0;
    }

    16%, 23% {
        width: 2.1rem;
    }
}

@keyframes text3_mobile {
    0%, 26%, 39%, 100% {
        width: 0;
    }

    29%, 36% {
        width: 2.9rem;
    }
}

@keyframes text4_mobile {
    0%, 39%, 52%, 100% {
        width: 0;
    }

    42%, 49% {
        width: 5rem;
    }
}

@keyframes text5_mobile {
    0%, 52%, 70%, 100% {
        width: 0;
    }
    58%, 65% {
        width: 8.2rem;
    }
}

@keyframes text6_mobile {
    0%, 70%, 97%, 100% {
        width: 0;
    }

    73%, 78% {
        width: 4.4rem;
        color: #fcf113;
    }
    83%, 97% {
        width: 4.4rem;
        color: #fcf113;
    }
}
