.main-content {
    min-height: calc(100vh);
    display: flex;
    flex-direction: column;
}

#segment-header-footer {
    background-color: #1e233a !important;
    margin-top: auto;
    z-index: 1000;

}

.header-main-desktop {
    /*background: url('https://static-images123.s3.eu-west-3.amazonaws.com/1618139117wavy-lines-white-bkg.svg') center top /  cover no-repeat,*/
    /*            url('https://static-images123.s3.eu-west-3.amazonaws.com/1618139117wavy-lines-white-bkg.svg') center top / cover no-repeat !important;*/

    background: url('https://static-images123.s3.eu-west-3.amazonaws.com/1618139117wavy-lines-white-bkg.svg') 30% 21% / cover no-repeat !important;
}

#desktopHeaderMenu * > .link {
    color: white !important;
    margin-top: 0.5em;
}

#desktopHeaderMenu * > a.item:hover {
    font-weight: bold;
    background: inherit;
}

#job-post-button {
    margin-bottom: 0.5em;
    padding-bottom: 1.3em;
    padding-top: 1.45em;
    padding-left: 3.85em !important;
    color: #ffffff !important;
    background-color: #e03997 !important;
    border-radius: 1em 1em;
    font-size: 0.75em;
    font-weight: 900;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15) !important;
    transition: box-shadow 0.3s ease-in-out;
}

#job-post-button:hover {
    box-shadow: 0 1px 10px rgba(255, 255, 255, 0.8) !important;
}

@media (min-width: 355px) {
    #job-post-button.mobile-job-post-button {
        padding-left: 10em !important;
        padding-right: 10em !important;
    }
}

/* Makes changing the size of the button available */
#job-post-button.mobile-job-post-button {
    margin-top: 2em;
    margin-left: 0.3em;
    font-size: inherit !important;
}

@media (max-width: 355px) {
    #job-post-button.mobile-job-post-button {
        padding-left: 8em !important;
        padding-right: 8em !important;
    }
}


#sidebar-opened-mobile-header {
    background-color: #1e233a
}

#sidebar-opened-mobile-header > a.link {
    color: #ffffff !important;
}

#apply-button {
    margin-top: 0.2em;
    border-radius: 0.35em 0.35em 0.35em 0.35em;
    background-color: blue;
    background-image: linear-gradient(to right, rgba(224, 57, 151, 0.9), rgba(33, 186, 69, 0.4));
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15) !important;
    transition: box-shadow 0.3s ease-in-out;
}

#apply-button:hover {
    box-shadow: 0 1px 10px rgba(255, 0, 255, 0.7) !important;
}

#offer-card {
    box-shadow: 0 0 0 1px #d4d4d5, 0 1.8px 0 0 #9627ba, 0 1px 3.5px 0 #9627ba !important;
    margin-top: 0.25em
}

.ui.search > .results {
    z-index: 2000;
}


#offer-card.desktop {
    border-radius: 0.6em 0.6em !important;
}


.ui.detail_label {
    background-color: #1e233a !important;
    color: white;
    margin-left: 0;
    margin-bottom: 0.2em;
    margin-right: 0.2em
}

.ui.detail_label.date {
    background-color: #1e233abf !important;
}

.ui.text.container {
    max-width: 900px !important;
}

#offer-card.featured {
    background-color: #1e233a0a
}

#offer-card.featured {
    box-shadow: 0 0 0 3px #d4d4d5, 0 0 6px 0 #9627ba, 0 1px 8.5px 0 #9627ba !important;
}

#offer-card > .ui.corner.label {
    border-color: #22242600
}

#offer-card * > i.icon {
    color: #fbbd08 !important;
}

#grid-subscribe-button {
    padding-left: 0 !important;
}

#grid-row-subscribe {
    padding-bottom: 0 !important;
    display: compact !important;

}

#subscribe-form-field {
    border-radius: 0.5rem !important;
}

/*#footer-message {*/
/*    margin-bottom: 0;*/
/*}*/
.footer-twitter {
    margin-left: 0.25em;
}

#desktopHeaderMenu > .ui.text.container {
    max-width: 1000px !important;
}

.slideshow-container {
    overflow: hidden;
    width: 100%;
    margin-bottom: 2em;
}

.logo-container {
    display: flex;
    animation: slide 45s linear infinite;
    transform: translateX(-50%);
}

.logo-container.mobile {
    display: flex;
    transform: translateX(-50%);
    animation: slide 15s linear infinite;
}

.logo-container > img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
}


@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

#offer-card h2 {
    margin-bottom: 0;
}