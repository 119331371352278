#grid-row-logo-company_name {
    margin-bottom: 0.5em;
    margin-left: 0.17em;
}

#offer-title {
    margin-bottom: 0.8em;
}

#offer-title > h1 {
    font-size: 3rem;
}

#offer-company_name {
    color: slategray;
    padding-left: 0.2em;
}

#offer-logo {
    padding-right: 0 !important;
}

.type-gray-text {
    color: slategray;
    font-weight: 600;
}

#offer-other {
    margin-bottom: 1em;
}

#apply-button.button-single_offer_page {
    margin-top: 1.5em;
    margin-bottom: 1em;
}

.single-offer-end {
    margin-bottom: 3em !important;
}

.job-post-form {
    margin-bottom: 3em;
}

#offer-description {
    margin-top: 10em;
    margin-bottom: 10em;
}

.socials-column > .icon {
    margin-right: 0.2em;
}