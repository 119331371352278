/* COMMON START */

#result-count-and-day-filter-grid-row {
    align-items: flex-end;
    margin-bottom: 0.35em;
}

/* nb of jobs text */
#result-count-grid-column {
    padding-left: 0.3em;
    color: #000000de;
    font-weight: 700 !important;
}

/* 'jobs' text' */
#result-count-grid-column > span {
    font-weight: 100 !important;
}

.filter-grid-column > div > .ui.basic.button {
    color: black !important;
}

.filter-grid-column * > .dropdown > .menu.transition {
  border-radius: 0.8em !important;
}

.toggle-text {
    font-weight: 900;
    margin-right: 0.4em;
    font-size: 0.85em;
}

.toggle-thing {
    display: inline-flex !important;
    align-items: center;
}


.filter-drop * > .menu.transition {
    z-index: 3000;
}

#result-count-and-day-filter-grid-row * > .menu.transition {
    z-index: 3000;
}

.filter-drop > .dropdown {
    border-radius: 0.7rem !important;
}

.jobs-search-bar * > input.prompt {
    border-radius: 1rem !important;
}

/* role dropdown is shorter so this matches it to social dropdown*/
.filter-drop > div {
    /*max-width: 8.3em;*/
    min-width: 8.2em;
}

.filter-drop * > .menu {
    max-height: 20rem !important;
}


div[id*="search-and-filter-grid"] * > .ui.dropdown .menu > .header {
    font-weight: 1100 !important;
    font-size: 0.9rem;
}

div[id*="search-and-filter-grid"] * > .ui.labeled.icon.button > .icon {
    background-color: #1e233a;
}

div[id*="search-and-filter-grid"] * > .ui.labeled.icon.button > .icon:before {
    color: white;
}

@media (min-width: 755px) and (max-width: 993px) {
   .jobs-search-bar * > .ui.icon.input > input {
       padding-right: 0 !important;
   }
}

/* NAV BAR -------------------- */

.category-navigation {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
}

.category-navigation > .ui.black.basic {
    background-color: #1e233a !important;
    color: white !important;
}

.category-navigation > .ui.button {
    margin-right: 0.55em;
    margin-bottom: 0.4em;

}


/* NAV BAR --------------------

/* COMMON END */

/*-------------------------------------------------------*/

/* MOBILE START */

#mobile-search-and-filter-grid {
    margin-left: 0.4em !important;
    margin-right: 0.4em !important;
}

#mobile-search-and-filter-grid  > #role-row {
    padding-bottom: 0 !important;
    padding-top: 0.3em;
}

#role-row > .column.left {
    padding-right: 0.2em;
    padding-left: 0;
}

#role-row > .column.right {
    padding-right: 0;
    padding-left: 0.2em;
}

#mobile-search-and-filter-grid  > #filter-row  {
    padding-top: 0.5em !important;
}

.filter-grid-column.mobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

#result-count-and-day-filter-grid-row > #mobile-result-count-and-day-filter-grid-right {
    padding-right: 0 !important;
}

.toggle-thing.mobile {
    padding-left: 0.34em !important;
    padding-right: 0 !important;
    min-width: 6em !important;

}

.toggle-thing.mobile.right {
    padding-left: 0.65em !important;
}

.filter-drop.mobile > div {
    margin-bottom:  0.3em !important;
}

.filter-drop.mobile * > .item {
    min-width: 13em;
}


/* MOBILE END */

/*-------------------------------------------------------*/

/* DESKTOP START */

#desktop-search-and-filter-grid {
    margin-left: 0.4em !important;
}

.toggle-thing.desktop {
    padding-left: 0.3em !important;
    padding-right: 0 !important;

}

.filter-grid-column.desktop {
    padding-left: 1.1rem !important;
}

#desktop-role-filter-row {
    padding-bottom: 0;
    padding-top: 0.2em;
}

.filter-grid-column.desktop * {
    min-width: max-content;
}

.category-navigation.desktop  {
    text-align: center;
}