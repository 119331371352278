.big-size-header {
    font-size: 3rem !important;
}

#tos-intro {
    margin-bottom: 3em;
}

#last-updated {
    margin-bottom: 2rem;
    text-align: center;
}

.numbered-terms-header > .sub.header {
    margin-top: 1rem !important;
}

.about-header-big {
    padding: 1.5rem 0 !important;
}