.green-text {
  color: #21ba45;
}
.white-text {
    color: white
}

.about-header {
  padding: 2rem 0;
  background: rgb(30, 35, 58);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.header-margin {
    margin-bottom: 3rem;
}

.about-section {
    padding: 2.5rem 0;
    font-size: 1.3rem;
}

.about-list {
    font-weight: 700;
}

.custom-segment {
  margin-top: 3rem !important;
  background-color: #f9fafb !important;
  padding: 2rem 1.5rem !important;;
  border-radius: 12px !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) !important;
}

.custom-header {
  font-size: 1.75rem !important;
  font-weight: 800 !important;
  color: #3a3a3a !important;
}

.custom-paragraph {
  font-size: 1.125rem !important;
  line-height: 1.8 !important;
  color: #4a4a4a !important;
}

/* Contact */

.main-header-contact {
    padding: 1.5rem;
}

.main-header-contact > h2 {
    margin: 0 !important;
}

.margin-contact-links {
 margin-top: 3rem !important;
}