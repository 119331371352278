.keywords {
    margin-bottom: 0 !important;
}

.type-gray-text {
    color: gray;
    font-weight: 200;
}

.form-fields {
    margin-bottom: 3em !important;
}

.ui.steps .step > .icon {
    font-size: 1.2rem
}

.ui.steps .step {
    padding: 1em !important;
}

.remote-text {
    font-weight: 900;
    margin-right: 1em;
}

.left-margin {
    margin-left: 1em
}

.remote-field {
    margin-bottom: 1em;
    display: inline-flex;
}

i.icon.discord-icon {
    color: #7289DA !important;
}

.apply-type-group {
    margin-bottom: 1.2em !important;
}

.white-text-button {
    color:white !important;
}

#preview-offer * > .ui.card {
    width: 100% !important;
}

#preview-offer * > .preview-label {
    display: flex;
    flex-direction: column;
    text-align: center !important;
    margin-bottom: 1em;
    margin-top: 1em;
}

.button-finalize {
    margin-top: 3.5em !important;
    margin-bottom: 1em !important;
}

#logo-upload {
    display: none;
    margin-left: 3em !important;

}

.logo-label {
    margin-left: 2em;
}

.logo-upload-form-field  > .ui.pointing.above.prompt.label {
    margin-top: 0.5em !important;
    margin-left: 0.9em !important;
}

#recaptcha-div  > div {
    text-align: center !important;
    display: inline-block;
}

#recaptcha-div {
    text-align: center;
    margin-bottom: 2em;
}